@import url(https://fonts.googleapis.com/css?family=Oswald);
@import url(https://fonts.googleapis.com/css?family=Josefin+Sans);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.qrdiv{
  width: 190px;
  height: 190px;
  background-color: white;
  padding: 5px;
}

/*dark background to support form theme*/
body{
  background:url(/static/media/dark_wall.6fb19d93.png);
}

h1{
  padding:5px 0px 0px 0px;
  font:25px Oswald;
  color:#FFF;
  text-shadow:#000 0px 1px 5px;
  margin:0px;
  overflow-wrap: normal;
}

h2{
  padding:5px 0px 0px 0px;
  font:25px Oswald;
  color:rgb(47, 182, 35);
  text-shadow:#000 0px 1px 5px;
  margin:0px;
}

.h2Red{
  padding:5px 0px 0px 0px;
  font:25px Oswald;
  color:rgb(207, 3, 3);
  text-shadow:#000 0px 1px 5px;
  margin:0px;
}

.h2Yellow{
  padding:5px 0px 0px 0px;
  font:25px Oswald;
  color:rgba(223, 202, 16, 0.911);
  text-shadow:#000 0px 1px 5px;
  margin:0px;
}

p{
  font:13px Open Sans;
  color:#6E6E6E;
  text-shadow:#000 0px 1px 5px;
  margin-bottom:5px;
}


input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type="text"],input[type="password"],input[type="number"]{
  width:98%;
  padding:15px 15px 15px 15px;
  border-radius:5px;
  box-shadow:inset 4px 6px 10px -4px rgba(0,0,0,0.3), 0 1px 1px -1px rgba(255,255,255,0.3);
	background:rgba(0,0,0,0.2);
  border:1px solid rgba(0,0,0,1);
  margin-bottom:10px;
  margin-top: 10px;
  color:#6E6E6E;
  text-shadow:#000 0px 1px 5px;
}

button[type="submit"]{
  width:100%;
  padding:15px;
  border-radius:5px;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(rgb(55, 222, 40)), to(rgb(47, 182, 35)));
  background-image: -webkit-linear-gradient(rgb(55, 222, 40) 0%, rgb(47, 182, 35) 100%);
  background-image: linear-gradient(rgb(55, 222, 40) 0%, rgb(47, 182, 35) 100%);
  font:14px Oswald;
  color:#FFF;
  text-transform:uppercase;
  text-shadow:#000 0px 1px 5px;
  border:1px solid #000;
  opacity:0.7;
	box-shadow: 0 8px 6px -6px rgba(0,0,0,0.7);
  border-top:1px solid rgba(255,255,255,0.8)!important;
  -webkit-box-reflect: below 0px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(50%, transparent), to(rgba(255,255,255,0.2)));
}

.betup{
  width:90%;
  padding:15px;
  margin-top: 12px;
  border-radius:5px;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(rgb(55, 222, 40)), to(rgb(47, 182, 35)));
  background-image: -webkit-linear-gradient(rgb(55, 222, 40) 0%, rgb(47, 182, 35) 100%);
  background-image: linear-gradient(rgb(55, 222, 40) 0%, rgb(47, 182, 35) 100%);
  font:14px Oswald;
  color:#FFF;
  text-transform:uppercase;
  text-shadow:#000 0px 1px 5px;
  border:1px solid #000;
  opacity:0.7;
	box-shadow: 0 8px 6px -6px rgba(0,0,0,0.7);
  border-top:1px solid rgba(255,255,255,0.8)!important;
  -webkit-box-reflect: below 0px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(50%, transparent), to(rgba(255,255,255,0.2)));
}
.betdown{
  width:90%;
  padding:15px;
  margin-top: 12px;
  border-radius:5px;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(rgb(207, 3, 3)), to(rgb(212, 47, 47)));
  background-image: -webkit-linear-gradient(rgb(207, 3, 3) 0%, rgb(212, 47, 47) 100%);
  background-image: linear-gradient(rgb(207, 3, 3) 0%, rgb(212, 47, 47) 100%);
  font:14px Oswald;
  color:#FFF;
  text-transform:uppercase;
  text-shadow:#000 0px 1px 5px;
  border:1px solid #000;
  opacity:0.7;
	box-shadow: 0 8px 6px -6px rgba(0,0,0,0.7);
  border-top:1px solid rgba(255,255,255,0.8)!important;
  -webkit-box-reflect: below 0px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(50%, transparent), to(rgba(255,255,255,0.2)));
}

.signout{
  width:25%;
  float: right!important;
  padding:15px;
  border-radius:5px;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(rgb(207, 3, 3)), to(rgb(212, 47, 47)));
  background-image: -webkit-linear-gradient(rgb(207, 3, 3) 0%, rgb(212, 47, 47) 100%);
  background-image: linear-gradient(rgb(207, 3, 3) 0%, rgb(212, 47, 47) 100%);
  font:14px Oswald;
  color:#FFF;
  text-transform:uppercase;
  text-shadow:#000 0px 1px 5px;
  border:1px solid #000;
  opacity:0.7;
	box-shadow: 0 8px 6px -6px rgba(0,0,0,0.7);
  border-top:1px solid rgba(255,255,255,0.8)!important;
  -webkit-box-reflect: below 0px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(50%, transparent), to(rgba(255,255,255,0.2)));
}
.betdown:hover,.betup:hover, .signout:hover{
  opacity:1;
  cursor:pointer;
}
.bitimg{
  height:100%;
  width: 100%;
  padding: 10px;
}

.usernamesi-help,.passwordsi-help,.usernamesu-help,.passwordsu-help{
  display:none;
  padding:0px;
  margin:0px 0px 15px 0px;
}

input:focus{
  box-shadow:inset 4px 6px 10px -4px rgba(0,0,0,0.7), 0 1px 1px -1px rgba(255,255,255,0.3);
  background:rgba(0,0,0,0.3);
}

button[type="submit"]:hover{
  opacity:1;
  cursor:pointer;
}

.username-help,.password-help{
  display:none;
  padding:0px;
  margin:0px 0px 15px 0px;
}

.optimize{
  position:fixed;
  right:3%;
  top:0px;
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.column-in-center{
  float: none;
  margin: 0 auto;
}

input.error {
  border: 1px solid #8B0000;
}

.errorMessage {
  font:13px Open Sans;
  color: red;
  text-shadow:#000 0px 1px 5px;
  margin-bottom:30px;
}

.possibleWin {
  font:13px Open Sans;
  color: rgb(160, 240, 157);
  text-shadow:#000 0px 1px 5px;
  margin-bottom:-10px;
}

.myWallet
{
  font-size: 20px;
  text-align: center;
  overflow-wrap: break-word;
}

.col-centered{
  float: none;
  margin: 0 auto;
}

#inputCenteredText
{
  text-align: center;
}

.bets-list {
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
}

.bets-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.bet-item {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
}

.bet-item > div {
  width: 33%;
}

.bets-list > p {
  width: 100%;
  color: white;
  font-size: 20px;
}

.centered-text {
  width: 100%;
  text-align: center;
}
